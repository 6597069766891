.navbar-toggler {
    background-color: rgba(255, 255, 255, 0.1);
}

.top-row {
    height: 3.5rem;
    background-color: rgba(0,0,0,0.4);
}

.navbar-brand {
    font-size: 1.1rem;
}

.oi {
    width: 2rem;
    font-size: 1.1rem;
    vertical-align: text-top;
    top: -2px;
}

.nav-item {
    font-size: 0.9rem;
    padding-bottom: 0.5rem;
}

    .nav-item:first-of-type {
        padding-top: 1rem;
    }

    .nav-item:last-of-type {
        padding-bottom: 1rem;
    }

    .nav-item a {
        color: #d7d7d7;
        border-radius: 4px;
        height: 3rem;
        display: flex;
        align-items: center;
        line-height: 3rem;
    }


.nav-item a:hover {
    background-color: rgba(255,255,255,0.1);
    color: white;
}

.activeTwo{
    background-color: rgba(255,255,255,0.1);
    color: white;
}
.active{
    background-color: rgba(255,255,255,0.1);
    color: white;
}



@media (min-width: 641px) {
    .navbar-toggler {
        display: none;
    }

    .collapse {
        /* Never collapse the sidebar for wide screens */
        display: block;
    }
}

/*TOOLTIPS*/

/*NavLink[title]:hover::after {
    content: attr(title);
    position: absolute;
    top: -100%;
    left: 0;
}*/

[data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
}

[data-title]:after {
    content: attr(data-title);
    position: absolute;
    bottom: -1.6em;
    left: 100%;
    padding: 4px 4px 4px 8px;
    color: #222;
    white-space: nowrap;
    border-radius: 5px;
    box-shadow: 0px 0px 4px #222;
    background-image: -o-linear-gradient(top, #f8f8f8, #cccccc);
    opacity: 0;
    z-index: 99999;
    visibility: hidden;
}

[data-title] {
    position: relative;
}

.nav-item a{
    color: #d7d7d7;
    border-radius: 4px;
    height: 3rem;
    display: flex;
    align-items: center;
    line-height: 3rem;
}

.nav-item{
    padding-bottom: 0.5rem;
}
/* 
.page-sidebar, .page-logo{
    background-color: #0F3CD8 !important;
} */

.dd-lang {
    display: block;
    width: 100%;
    padding: 0.75rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    }
    
    /*@keyframes blinking {
        0% {
            background-color: #666666;
        }
    
        100% {
            background-color: #2198F3;
        }
    }
    
    
    .fa-bell {
        animation: blinking 1s infinite;
    }*/
    
    .hover-white:hover {
        background: #e7e7e7;
        -webkit-text-emphasis-color: #785BA5;
                text-emphasis-color: #785BA5;
        -webkit-text-decoration-color: #785BA5;
                text-decoration-color: #785BA5;
        color: #785BA5;
    }
    
    
.modal-dialog-full-width {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    max-width:none !important;

}

.modal-content-full-width  {
    height: auto !important;
    min-height: 100% !important;
    border-radius: 0 !important;
    background-color: #ececec !important 
}

.modal-header-full-width  {
    border-bottom: 1px solid #9ea2a2 !important;
}

.modal-footer-full-width  {
    border-top: 1px solid #9ea2a2 !important;
}

.modal-fullscr{
    width: 0vw;
    max-width: none;
    /* height: 92.5%; */
    height: calc(100% - 70px);
    margin: 0;
    padding: 0;
}

.modal-fullscr .modal-content{
    /* width: 95.7vw; */
    width: calc(100vw - 77px);
    max-width: none;
    height: 100%;
    margin: 0;

    padding: 0;
    left: 77px;
    top: 70px;
}


.page {
    position: relative;
    display: flex;
    flex-direction: column;
}

.main {
    flex: 1 1;
}

.sidebar {
    background-image: linear-gradient(180deg, rgb(5, 39, 103) 0%, #3a0647 70%);
}

.sidebar-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0;
    height: 100vh;
}

.header-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.top-row {
    background-color: #f7f7f7;
    border-bottom: 1px solid #d6d5d5;
    justify-content: flex-end;
    height: 3.5rem;
    display: flex;
    align-items: center;
}

    .top-row ::deep a, .top-row .btn-link {
        white-space: nowrap;
        margin-left: 1.5rem;
    }

    .top-row a:first-child {
        overflow: hidden;
        text-overflow: ellipsis;
    }

@media (max-width: 640.98px) {
    .top-row:not(.auth) {
        display: none;
    }

    .top-row.auth {
        justify-content: space-between;
    }

    .top-row a, .top-row .btn-link {
        margin-left: 0;
    }
}

@media (min-width: 641px) {
    .page {
        flex-direction: row;
    }

    .sidebar {
        width: 250px;
        height: 100vh;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
    }

    .top-row {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1;
    }

    .main > div {
        padding-left: 2rem !important;
        padding-right: 1.5rem !important;
    }
}

.img-fix{
    width:64px;
    height:64px;
}
.editIconProfile:hover{
    background-color: gray;
    opacity: 0.5;
    cursor: pointer;
  }
.marginLeftImgProfile{
    margin-left: 25px !important;
}  
.profileEmailSpan{
    font-size: 18px;    
}
.profileEmailProvider{
    font-size: 12px;
    color: lightslategrey    
}
.disabledLink{
    color: lightgray !important;
    cursor: not-allowed !important;
}
@media screen and (max-width: 1024px) {
    .marginLeftImgProfile{
        margin-left: 0px !important;
    }   

}
@media screen and (max-width: 400px) {
    .profileEmailSpan{
        font-size: 14px;    
        margin-top: 30px;
    }
    .profilePhoto{
        align-self: center;
    }
}
.stateName{
    font-size: 25px;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.w-10{
    width: 10%;
}
.buttonPagada{
    background: #1dc9b7;
    color: white;
    padding: 5px;
    border-radius: 50px;
    height: 23px;
    display: flex;
    cursor: default;
    align-items: center;
    justify-content: center;

}
.buttonPendiente{
    background: #ff0000;
    color: white;
    padding: 5px;
    border-radius: 50px;
    height: 23px;
    display: flex;
    cursor: default;
    align-items: center;
    justify-content: center;
}

.waStatusUnpaired,.waStatusConnected, .waStatusWarning{
    color: white;
    padding: 5px;
    border-radius: 50px;
    height: 23px;
    display: flex;
    cursor: default;
    align-items: center;
    justify-content: center;
    width: -webkit-max-content;
    width: max-content
    
}
.waStatusUnpaired{
    background: #f44336;
}
.waStatusConnected{
    background: #66bb6a;
}
.waStatusWarning{
    background: #ffa726;

}



.spanButton{
    background: white;
    color: black;
    padding: 0px 10px 0px 5px;
    border-radius: 0px 7px 7px 0px;
    width: 100%;
    margin-left: 8px;
    white-space: nowrap;
    margin-right: -2px;
    font-weight: 400;
    /* text-transform: lowercase; */
}

.lineVertical{
    border-left: 1px solid #ffffffd9;
    height: 20px;
    margin: 0px 7px 0px 7px;
}

.saldoSize{
    font-size: 30px;
    margin-top: 10px;
}


.alertPago{
    
    height: 50px;
    /* place-self: center; */
    padding-top: 11px;
    background: #dedede;
    font-size: 20px;
    padding-left: 20px;
    border-left: 5px solid #1dc9b7;
    border-radius: 2px 0px 0px 2px;

}
.alertPagoVencido{
    
    /* height: 50px;
    background: #dedede;
    font-size: 20px;
    padding-left: 20px;
    border-left: 5px solid #ff0000;
    border-radius: 2px 0px 0px 2px;
    padding-top: 10px */
    min-height: 50px;
    background: #dedede;
    font-size: 20px;
    /* padding-left: 20px; */
    border-left: 5px solid #ff0000;
    border-radius: 2px 0px 0px 2px;
    /* padding-top: 10px;*/
    

}

/* grid */
.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-auto-columns: 1fr;
    grid-gap: 0px 0px;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      "periodo vencimiento"
      "unset fechaPago";
  }

  
  .periodo { grid-area: periodo; }
  
  .vencimiento { grid-area: vencimiento; }
  
  .unset { grid-area: unset; }
  
  .fechaPago { grid-area: fechaPago; }

  /* fin grid  */
  .stateBillingBox{
      display: flex;
      justify-items: space-between;
      align-items: center;
}
.box_payment {
    display: flex;
    padding: 10px 20px;
    border: 1px solid lightgray;
    justify-content: space-between;
}
@media screen and (max-width: 768px) {
    .stateBillingBox {
        display: block;
        justify-items: unset;
        align-items: unset;
    }

    .box_payment {
        display: flex;
        flex-direction: column;
        padding: 10px 20px;
        border: 1px solid lightgray;
    }


}
