.w-10{
    width: 10%;
}
.buttonPagada{
    background: #1dc9b7;
    color: white;
    padding: 5px;
    border-radius: 50px;
    height: 23px;
    display: flex;
    cursor: default;
    align-items: center;
    justify-content: center;

}
.buttonPendiente{
    background: #ff0000;
    color: white;
    padding: 5px;
    border-radius: 50px;
    height: 23px;
    display: flex;
    cursor: default;
    align-items: center;
    justify-content: center;
}

.waStatusUnpaired,.waStatusConnected, .waStatusWarning{
    color: white;
    padding: 5px;
    border-radius: 50px;
    height: 23px;
    display: flex;
    cursor: default;
    align-items: center;
    justify-content: center;
    width: max-content
    
}
.waStatusUnpaired{
    background: #f44336;
}
.waStatusConnected{
    background: #66bb6a;
}
.waStatusWarning{
    background: #ffa726;

}



.spanButton{
    background: white;
    color: black;
    padding: 0px 10px 0px 5px;
    border-radius: 0px 7px 7px 0px;
    width: 100%;
    margin-left: 8px;
    white-space: nowrap;
    margin-right: -2px;
    font-weight: 400;
    /* text-transform: lowercase; */
}

.lineVertical{
    border-left: 1px solid #ffffffd9;
    height: 20px;
    margin: 0px 7px 0px 7px;
}

.saldoSize{
    font-size: 30px;
    margin-top: 10px;
}


.alertPago{
    
    height: 50px;
    /* place-self: center; */
    padding-top: 11px;
    background: #dedede;
    font-size: 20px;
    padding-left: 20px;
    border-left: 5px solid #1dc9b7;
    border-radius: 2px 0px 0px 2px;

}
.alertPagoVencido{
    
    /* height: 50px;
    background: #dedede;
    font-size: 20px;
    padding-left: 20px;
    border-left: 5px solid #ff0000;
    border-radius: 2px 0px 0px 2px;
    padding-top: 10px */
    min-height: 50px;
    background: #dedede;
    font-size: 20px;
    /* padding-left: 20px; */
    border-left: 5px solid #ff0000;
    border-radius: 2px 0px 0px 2px;
    /* padding-top: 10px;*/
    

}

/* grid */
.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-auto-columns: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      "periodo vencimiento"
      "unset fechaPago";
  }

  
  .periodo { grid-area: periodo; }
  
  .vencimiento { grid-area: vencimiento; }
  
  .unset { grid-area: unset; }
  
  .fechaPago { grid-area: fechaPago; }

  /* fin grid  */
  .stateBillingBox{
      display: flex;
      justify-items: space-between;
      align-items: center;
}
.box_payment {
    display: flex;
    padding: 10px 20px;
    border: 1px solid lightgray;
    justify-content: space-between;
}
@media screen and (max-width: 768px) {
    .stateBillingBox {
        display: block;
        justify-items: unset;
        align-items: unset;
    }

    .box_payment {
        display: flex;
        flex-direction: column;
        padding: 10px 20px;
        border: 1px solid lightgray;
    }


}